import { useCallback, useContext, useEffect, useState, useMemo } from 'react'
import cn from 'clsx'
import _keys from 'lodash/keys'
import _isUndefined from 'lodash/isUndefined'
import { useTranslation, Trans } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import * as U from 'utils'
import * as H from 'hooks'
import * as C from 'components'
import UIContext from 'ui_context'
import AuthContext from 'auth_context'
import NetEmissionsChart from '../../components/NetEmissionsChart/NetEmissionsChart'
import InsightsSideBar from './components/InsightsSidebar/InsightsSidebar'
import Banner from '../../components/Banner/Banner'
import useHasGlobalAverageData from '../../hooks/useHasGlobalAverageData'
import Map from '../../components/Map/Map'

import {
  type EmissionsFilters,
  type EmissionsGroup,
  type EmissionsData,
  type Emissions
} from 'types/data'

import './style.scss'

const CLASS_NAME = 'cst-page-insights'
const CLASS_NAME_MAP = 'cst-page-insights-map'

export enum InsightsView {
  ANALYTICS = 'Analytics',
  MAP = 'Map'
}

type Props = {
  className?: string
  view: InsightsView
}

export default function Insights({
  className,
  view = InsightsView.ANALYTICS
}: Props) {
  const { user } = useContext(AuthContext)
  const location = useLocation()
  const { search } = location
  const pathName = location.pathname
  const urlEID = search.split('=')[1]

  H.useEnforceAuthByNavigate(user)
  H.useDocumentTitle(view)

  const { t } = useTranslation()
  const { userSub, sub, emissions } = useContext(UIContext)
  const hasGlobalAverageData = useHasGlobalAverageData()
  const isViewingDifferentSub = userSub !== sub
  const showBanner = hasGlobalAverageData || isViewingDifferentSub
  const urlEmissions = emissions?.find(({ id }) => id === urlEID)

  const [markersVisible, setMarkersVisible] = useState<boolean>(false)

  const emissionsMinDate = H.useFunctionMemo<Date | null>(
    U.getEmissionsMinDate,
    emissions ?? []
  )

  const emissionsMaxDate = H.useFunctionMemo<Date | null>(
    U.getEmissionsMaxDate,
    emissions ?? []
  )

  const [filters, setFilters] = useState<EmissionsFilters>(
    U.getNewEmissionsFilters(emissions, emissionsMinDate, emissionsMaxDate)
  )

  const { start, end, sortMode, groupMode } = filters
  const setFilterValue = useCallback(
    (key: string, value: any) => {
      setFilters((prevFilters: EmissionsFilters) => ({
        ...prevFilters,
        [key]: value
      }))
    },
    [setFilters]
  )

  const [selectedEmissions, setSelectedEmissions] = useState<Emissions[]>([])
  const { infoModalEmissions, onInfoModalClose, onInfoModalOpen } =
    H.useInfoModal()

  const finalClassName = H.useClassName(CLASS_NAME, className)

  useEffect((): void => {
    if (emissions === null) {
      setFilterValue('start', null)
      setFilterValue('end', null)
    } else {
      setFilterValue('start', emissionsMinDate)
      setFilterValue('end', emissionsMaxDate)
    }
  }, [setFilterValue, emissions, emissionsMinDate, emissionsMaxDate])

  const filteredEmissions = H.useFunctionMemo<Emissions[]>(
    U.getFilteredEmissions,
    emissions ?? [],
    filters
  )

  const sortedEmissions = H.useFunctionMemo<Emissions[]>(
    U.getSortedEmissions,
    filteredEmissions,
    sortMode
  )

  const groupedEmissions = H.useFunctionMemo<EmissionsGroup[]>(
    U.getGroupedEmissions,
    sortedEmissions,
    groupMode,
    sortMode
  )

  const filteredSelectedEmissions = H.useFunctionMemo<Emissions[]>(
    U.getFilteredEmissions,
    selectedEmissions,
    filters
  )

  const selectedGroups: EmissionsGroup[] = H.useFunctionMemo(
    U.getSelectedEmissionGroups,
    groupedEmissions,
    filteredSelectedEmissions
  )

  const mainGraphEmissions = useMemo((): Emissions[] => {
    if (selectedGroups.length > 1) {
      return selectedGroups.map((group: EmissionsGroup) =>
        U.getMergedEmissionsForGroup(group)
      )
    } else if (filteredSelectedEmissions.length === 0) {
      return sortedEmissions
    } else {
      return filteredSelectedEmissions
    }
  }, [sortedEmissions, selectedGroups, filteredSelectedEmissions])

  const selectedPolygonUUIDs = useMemo(
    (): string[] =>
      selectedEmissions.map(({ feature }) => feature.properties.feature_uuid),
    [selectedEmissions]
  )

  useEffect((): void => {
    if (urlEmissions !== undefined) {
      setTimeout((): void => {
        setSelectedEmissions([urlEmissions])
      }, 500)
    }
  }, [urlEmissions])

  const mainGraphYearlyEmissions = useMemo(
    (): Emissions[] =>
      mainGraphEmissions.map(({ data, ...otherEmissionsData }): Emissions => {
        const yearlyData: EmissionsData = {}

        _keys(data).forEach((key: string): void => {
          const year = key.substring(0, 4)

          if (_isUndefined(yearlyData[year])) {
            yearlyData[year] = data[key]
          } else {
            yearlyData[year] += data[key]
          }
        })

        return {
          ...otherEmissionsData,
          data: yearlyData
        }
      }),
    [mainGraphEmissions]
  )

  const mainGraphIsSplit = useMemo(
    (): boolean => selectedGroups.length > 1,
    [selectedGroups]
  )

  const hasData = filteredEmissions.length > 0 || start === null || end === null

  const numberOfSelectedPolygons = filteredSelectedEmissions.length
  const userHasSelectedPolygons = numberOfSelectedPolygons > 0

  const annualNEEChartTitle = userHasSelectedPolygons
    ? t('net_emissions_chart.analytics_annual.title', {
        count: numberOfSelectedPolygons
      })
    : t('net_emissions_chart.analytics_annual.title_default')

  const monthlyNEEChartTitle = userHasSelectedPolygons
    ? t('net_emissions_chart.analytics_monthly.title', {
        count: numberOfSelectedPolygons
      })
    : t('net_emissions_chart.analytics_monthly.title_default')

  const ChartPlaceholder = () => (
    <div>
      <div className="cst-chart-header-title">
        <Trans i18nKey="net_emissions_chart.title_total_nee_per_polygon" />
      </div>
      <div className="cst-chart-placeholder">
        <Trans i18nKey="analytics.chart_placeholder_text" />
      </div>
      <div className="cst-chart-header-title">
        <Trans i18nKey="baseline_chart.title" />
      </div>
      <div className="cst-chart-placeholder">
        <Trans i18nKey="analytics.chart_placeholder_text" />
      </div>
    </div>
  )

  const emissionsFilterHeaderTitle =
    pathName === '/analytics' ? t('analytics.title') : t('map.title')

  return (
    <div
      className={cn(finalClassName, {
        hideMarkers: !markersVisible,
        withSubWarning: isViewingDifferentSub,
        hasBanner: hasGlobalAverageData
      })}
    >
      {showBanner && (
        <Banner
          isViewingDifferentSub={isViewingDifferentSub}
          hasGlobalAverageData={hasGlobalAverageData}
        />
      )}
      <C.NavBar />
      <InsightsSideBar
        filters={filters}
        emissions={sortedEmissions}
        selectedGroups={selectedGroups}
        setFilterValue={setFilterValue}
        groupedEmissions={groupedEmissions}
        onShowFeatureInfo={onInfoModalOpen}
        selectedEmissions={selectedEmissions}
        setSelectedEmissions={setSelectedEmissions}
      />
      <div
        className={`${view === InsightsView.MAP ? CLASS_NAME_MAP : CLASS_NAME}-content-wrapper-outer`}
      >
        <C.EmissionsFilterHeader
          emissionsMinDate={emissionsMinDate}
          emissionsMaxDate={emissionsMaxDate}
          setFilterValue={setFilterValue}
          emissions={emissions}
          filters={filters}
          title={emissionsFilterHeaderTitle}
        />
        {!hasData && (
          <C.NoDataSkeleton label={t('analytics.no_data_message')} />
        )}
        {hasData && pathName === '/analytics' && (
          <C.FluidContentWrapper>
            <>
              <NetEmissionsChart
                split={mainGraphIsSplit}
                title={annualNEEChartTitle}
                emissions={mainGraphYearlyEmissions}
                hideMonth
              />
              <NetEmissionsChart
                split={mainGraphIsSplit}
                title={monthlyNEEChartTitle}
                emissions={mainGraphEmissions}
              />
              {userHasSelectedPolygons ? (
                <>
                  <NetEmissionsChart
                    emissions={filteredSelectedEmissions}
                    title={t('net_emissions_chart.title_total_nee_per_polygon')}
                    split
                  />
                  <C.BaselineChart emissions={filteredSelectedEmissions} />
                </>
              ) : (
                <ChartPlaceholder />
              )}
            </>
          </C.FluidContentWrapper>
        )}
        {hasData && pathName === '/map' && (
          <Map
            filteredEmissions={filteredEmissions}
            selectedEmissions={selectedEmissions}
            setMarkersVisible={setMarkersVisible}
            selectedPolygonUUIDs={selectedPolygonUUIDs}
            setSelectedEmissions={setSelectedEmissions}
          />
        )}
        <C.EmissionsDetailsModal
          emissions={infoModalEmissions}
          onClose={onInfoModalClose}
        />
      </div>
    </div>
  )
}
