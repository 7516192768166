import { useMemo, useState, useEffect, useContext, useCallback } from 'react'
import cn from 'clsx'
import _uniq from 'lodash/uniq'
import _keys from 'lodash/keys'
import _isDate from 'lodash/isDate'
import _flatten from 'lodash/flatten'
import _isUndefined from 'lodash/isUndefined'
import { useTranslation } from 'react-i18next'

import Topbar from './components/Topbar/Topbar'
import DashboardCards from '../../components/DashboardCards/DashboardCards'
import NetEmissionsChart from '../../components/NetEmissionsChart/NetEmissionsChart'
import NEEPerformanceExtremesChart from '../../components/NEEPerformanceExtremesChart/NEEPerformanceExtremesChart'
import Banner from '../../components/Banner/Banner'
import useHasGlobalAverageData from '../../hooks/useHasGlobalAverageData'
import * as U from 'utils'
import * as H from 'hooks'
import * as C from 'components'
import UIContext from 'ui_context'
import AuthContext from 'auth_context'
import { type Emissions, type EmissionsData } from 'types'

import { DOCUMENT_TITLE, PATH, CLASS_NAME } from './const'
import { PolygonsPerformanceExtremes } from '../../components/NEEPerformanceExtremesChart/types'

import './style.scss'

type Props = {
  className?: string
}

export default function Dashboard({ className }: Props) {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const { userSub, sub, emissions } = useContext(UIContext)
  const hasGlobalAverageData = useHasGlobalAverageData()
  const isViewingDifferentSub = userSub !== sub
  const showBanner = hasGlobalAverageData || isViewingDifferentSub
  const finalClassName = H.useClassName(CLASS_NAME, className)
  const emissionsYearly = useMemo(
    () =>
      (emissions ?? []).map(({ data, ...otherEmissionsData }): Emissions => {
        const yearlyData: EmissionsData = {}

        _keys(data).forEach((key: string): void => {
          const year = key.substring(0, 4)

          if (_isUndefined(yearlyData[year])) {
            yearlyData[year] = data[key]
          } else {
            yearlyData[year] += data[key]
          }
        })

        return {
          ...otherEmissionsData,
          data: yearlyData
        }
      }),
    [emissions]
  )

  const [selectedEmissions, setSelectedEmissions] = useState<Emissions[]>(
    emissions ?? []
  )

  useEffect((): void => {
    if (emissions != null && emissions.length > 0) {
      setSelectedEmissions(emissions)
    }
  }, [emissions])

  const [selection, setSelection] = useState<PolygonsPerformanceExtremes>(
    PolygonsPerformanceExtremes.IntensityTenBestPerforming
  )

  H.useEnforceAuthByNavigate(user)
  H.useDocumentTitle(DOCUMENT_TITLE)

  const maxDate = H.useFunctionMemo<Date | null>(
    U.getEmissionsMaxDate,
    selectedEmissions
  )

  const [year, setYear] = useState<number | null>(
    maxDate === null ? null : maxDate.getUTCFullYear()
  )

  useEffect((): void => {
    if (_isDate(maxDate)) {
      setYear(maxDate.getUTCFullYear())
    }
  }, [maxDate])

  const handleDownloadAllClick = useCallback((): void => {
    const exportFileName = t('export.all_file_name')
    const emissionsKeys = _uniq(
      _flatten((emissions ?? []).map(({ data }) => data).map(_keys))
    )

    emissionsKeys.sort((a: string, b: string): number => +a - +b)

    const columnLabels = [
      t('export.column_id'),
      t('export.column_feature_uuid'),
      t('export.column_name'),
      t('export.column_site_name'),
      t('export.column_country_code'),
      t('export.column_land_cover'),
      t('export.column_area'),
      ...emissionsKeys
    ]

    const csv = [
      columnLabels.join(','),
      ...(emissions ?? []).map(({ id, feature, data }) =>
        [
          id,
          feature.properties.feature_uuid,
          feature.properties.name,
          feature.properties.site_name,
          feature.properties.country_code,
          feature.properties.land_cover,
          feature.properties.area,
          ...emissionsKeys.map((key: string): number => data[key] ?? '')
        ].join(',')
      )
    ].join('\n')

    U.downloadCSV(csv, exportFileName)
  }, [t, emissions])

  const dataIsIntensity =
    selection === PolygonsPerformanceExtremes.IntensityTenBestPerforming ||
    selection === PolygonsPerformanceExtremes.IntensityTenWorstPerforming

  // temporarily disabled due to bug, see more here https://app.asana.com/0/1205667342685007/1208710623089343/f
  const isNEEPerformanceExtremesChartEnabled = false

  if (emissions === null) {
    return <C.NoDataSkeletonPage className={finalClassName} />
  }

  return (
    <div
      className={cn(finalClassName, {
        withSubWarning: isViewingDifferentSub,
        hasBanner: hasGlobalAverageData
      })}
    >
      {showBanner && (
        <Banner
          isViewingDifferentSub={isViewingDifferentSub}
          hasGlobalAverageData={hasGlobalAverageData}
        />
      )}
      <C.NavBar />
      <div className={`${CLASS_NAME}-wrapper`}>
        <Topbar
          year={year}
          emissions={emissions}
          setYear={setYear}
          onDownloadAllClick={handleDownloadAllClick}
        />
        <C.FluidContentWrapper centered>
          {year !== null && (
            <DashboardCards emissions={emissions ?? []} year={year} />
          )}
          <NetEmissionsChart
            title={t('net_emissions_chart.dashboard_annual.title')}
            emissions={emissionsYearly ?? []}
            hideMonth
          />
          <NetEmissionsChart
            title={t('net_emissions_chart.dashboard_monthly.title')}
            emissions={emissions ?? []}
          />
          {isNEEPerformanceExtremesChartEnabled && (
            <NEEPerformanceExtremesChart
              year={year}
              dataIsIntensity={dataIsIntensity}
              selection={selection}
              setSelection={setSelection}
              setYear={setYear}
              emissions={emissions ?? []}
              selectedEmissions={selectedEmissions}
              setSelectedEmissions={setSelectedEmissions}
            />
          )}
        </C.FluidContentWrapper>
      </div>
    </div>
  )
}

export { PATH, CLASS_NAME }
