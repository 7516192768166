import { useState, useEffect, useRef, useContext } from 'react'
import { Trans } from 'react-i18next'
import { Tooltip } from 'react-tooltip'

import UIContext from 'ui_context'

import './style.scss'

const landCoverClassUrl =
  'https://fluxnet.org/data/badm-data-templates/igbp-classification/'

const fluxnetDatasetUrl = 'https://fluxnet.org/data/fluxnet2015-dataset/'

type Props = {
  isViewingDifferentSub: boolean
  hasGlobalAverageData: boolean
}

export default function Banner({
  isViewingDifferentSub,
  hasGlobalAverageData
}: Props) {
  const [showTooltip, setShowTooltip] = useState(false)
  const { sub } = useContext(UIContext)
  const timeoutRef = useRef<NodeJS.Timeout | number | null>(null)
  const isHoveringTooltip = useRef(false)
  const timeout = 300

  useEffect(() => {
    if (showTooltip) {
      const tooltipEl = document.getElementById('banner-tooltip')
      if (tooltipEl == null) return

      const handleTooltipEnter = () => {
        isHoveringTooltip.current = true
        if (timeoutRef.current != null) {
          clearTimeout(timeoutRef.current)
        }
        setShowTooltip(true)
      }

      const handleTooltipLeave = () => {
        isHoveringTooltip.current = false

        timeoutRef.current = setTimeout(() => {
          if (!isHoveringTooltip.current) {
            setShowTooltip(false)
          }
        }, timeout)
      }

      tooltipEl.addEventListener('mouseenter', handleTooltipEnter)
      tooltipEl.addEventListener('mouseleave', handleTooltipLeave)

      return () => {
        tooltipEl.removeEventListener('mouseenter', handleTooltipEnter)
        tooltipEl.removeEventListener('mouseleave', handleTooltipLeave)
      }
    }
  }, [showTooltip])

  const handleMouseEnter = () => {
    if (timeoutRef.current != null) {
      clearTimeout(timeoutRef.current)
    }
    setShowTooltip(true)
  }

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      if (!isHoveringTooltip.current) {
        setShowTooltip(false)
      }
    }, timeout)
  }

  return (
    <div className="banner">
      <Tooltip
        anchorSelect={'.banner-more-info'}
        place="bottom"
        noArrow
        style={{ maxWidth: '600px', pointerEvents: 'auto' }}
        isOpen={showTooltip}
        className="banner-tooltip"
        id="banner-tooltip"
      >
        <Trans
          i18nKey="banner_global_average.text"
          components={{
            firstLink: (
              <a
                href={landCoverClassUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'underline' }}
              />
            ),
            secondLink: (
              <a
                href={fluxnetDatasetUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'underline' }}
              />
            )
          }}
        >
          The CO₂ net ecosystem exchange estimates displayed in this account are
          calculated globally per IGBP land cover class, based on flux
          measurements from eddy covariance instruments worldwide, sourced from
          the FLUXNET2015 dataset. Annual and seasonal estimates incorporate
          data from all available years of station measurements. These values
          provide representative estimates at the average polygon level and can
          be used for reporting purposes as appropriate.
        </Trans>
      </Tooltip>
      <p>
        {isViewingDifferentSub && (
          <>
            <Trans i18nKey="sub_warning_bar.label_prefix" values={{ sub }}>
              Viewing data for sub {{ sub }}
            </Trans>
          </>
        )}
        {isViewingDifferentSub && hasGlobalAverageData && <>{'.\u00A0'}</>}
        {hasGlobalAverageData && (
          <Trans
            i18nKey="banner_global_average.summary"
            components={{
              span: (
                <span
                  className="banner-more-info"
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                />
              )
            }}
          >
            CO₂ estimates are based on global flux measurements. Hover here for
            more info.
          </Trans>
        )}
      </p>
    </div>
  )
}
