import { useContext } from 'react'
import UIContext from 'ui_context'

const subsWithGlobalAverageData = [
  '5a4ab595-776c-4423-a942-235389b83a6d',
  'fee666de-68d8-4d1a-bf2e-dc77f46fddac'
]

export default function useHasGlobalAverageData() {
  const { sub } = useContext(UIContext)

  return subsWithGlobalAverageData.includes(sub)
}
